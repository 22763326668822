/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-07 01:29:06
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-09 11:02:07
 * @Description: 请输入文件描述信息
 * @FilePath: \src\components\customerPicker\api.js
 */

import http from "@/network/network.js";

// 获取客户列表
export const getCustomerList = (params) =>
  http.get("/v1/Customer/GetCustomerList", params);
