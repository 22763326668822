<!--
 * @Author: 智客云网络科技
 * @Date: 2022-02-09 10:45:15
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-12 18:01:40
 * @Description: 请输入文件描述信息
 * @FilePath: \src\components\customerPicker\index.vue
-->
<template>
  <van-popup
    v-model:show="showModule"
    position="right"
    closeable
    close-icon="close"
    :style="{ height: '100%', width: '100%' }"
  >
    <div class="kc-app">
      <van-nav-bar
        title="客户选择"
        left-arrow
        :fixed="true"
        :placeholder="true"
        :z-index="500"
        :safe-area-inset-top="true"
        @click-left="showModule = false"
      />

      <!-- 搜索框 -->
      <van-search
        v-model="searchKey"
        show-action
        label=""
        placeholder="客户姓名/电话号码"
        @search="onSearch"
      >
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>

      <!-- 组织筛选 -->
      <van-field
        v-model="kor_name"
        v-if="!one_kor"
        is-link
        readonly
        label="组织"
        placeholder="选择组织"
        @click="showKorPicker = true"
      />

      <!-- 列表 -->
      <van-list
        v-model:loading="loading"
        v-model:error="error"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell
          v-for="item in customerList"
          :key="item.kuser_id"
          :title="item.nickname"
          :value="item.phone || item.email"
          @click="pickItem(item)"
        />
      </van-list>

      <!-- 组织选择器 -->
      <organization-picker
        v-model:show="showKorPicker"
        v-model:kor_id="kor_id"
        v-model:kor_name="kor_name"
        v-model:one_kor="one_kor"
      ></organization-picker>
    </div>
  </van-popup>
</template>

<script>
import { getCurrentInstance, ref, watchEffect, watch } from "vue";
import { getCustomerList } from "./api";
import organizationPicker from "@/components/organizationPicker";
export default {
  components: {
    organizationPicker,
  },
  // props 注册接受 自定义标签
  props: {
    show: Boolean,
    customer_id: String,
    customer_name: String,
  },

  // emits 注册 接受自定义标签 属性绑定的事件
  emits: ["update:show", "update:customer_id", "update:customer_name"],

  setup(props, context) {
    const internalInstance = getCurrentInstance();
    const utils = internalInstance.appContext.config.globalProperties.$utils;
    const searchKey = ref(""); //搜索关键词
    const showModule = ref(false); //是否显示组件

    const one_kor = ref(false); //是否只有一个组织
    const showKorPicker = ref(false); //控制组织选择器是否显示
    const kor_id = ref(""); //组织ID
    const kor_name = ref(""); //组织名称

    const customerList = ref([]); //客户列表
    const loading = ref(false); //列表是否处于加载状态
    const error = ref(false); //列表是否加载失败
    const finished = ref(false); //列表是否完全加载完成
    const pageNo = ref(1); //当前页码

    //确认选择
    const pickItem = (item) => {
      context.emit("update:customer_id", item.kuser_id);
      context.emit("update:customer_name", item.nickname);
      showModule.value = false;
    };

    //监听显示
    watchEffect(() => {
      showModule.value = props.show;
    });

    //监听显示2
    watchEffect(() => {
      context.emit("update:show", showModule.value);
    });

    //组织ID变化
    watch(
      () => kor_id.value,
      () => {
        pageNo.value = 1;
        customerList.value = [];
        onLoad();
      }
    );

    //触发搜索
    const onSearch = () => {
      pageNo.value = 1;
      customerList.value = [];
      onLoad();
    };

    //触发列表加载
    const onLoad = () => {
      getCustomerList({
        pageNo: pageNo.value,
        pageSize: 25,
        kor_id: kor_id.value,
        searchKey: searchKey.value,
      })
        .then((res) => {
          //先页码+1 然后写入数据 再关闭加载状态
          pageNo.value = pageNo.value + 1;
          customerList.value = customerList.value.concat(res.data);
          loading.value = false;
        })
        .catch((err) => {
          if (err.code === 210004) {
            finished.value = true;
            loading.value = false;
            return;
          }
          error.value = true;
          loading.value = false;
          utils.fail(err.err_msg);
        });
    };

    return {
      customerList,
      loading,
      error,
      finished,
      searchKey,
      onSearch,
      pickItem,
      onLoad,
      one_kor,
      showKorPicker,
      kor_id,
      kor_name,
      showModule,
    };
  },
};
</script>
