<!--
 * @Author: 智客云网络科技
 * @Date: 2022-02-08 21:30:53
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-12 18:13:57
 * @Description: 库位初始化
 * @FilePath: \src\views\Storage\InitStorage.vue
-->

<template>
  <div class="kc-app">
    <van-nav-bar
      title="库位初始化"
      left-arrow
      :fixed="true"
      :placeholder="true"
      :z-index="500"
      :safe-area-inset-top="true"
      @click-left="$router.go(-1)"
    />

    <!-- 库位RFID -->
    <van-field
      v-model="kst_tag_id"
      label="库位RFID"
      ref="kst_tag_input_dom"
      autocomplete="off"
      @click="gainFocus()"
      placeholder="等待库位ID输入"
    />

    <!-- 存储方式 -->
    <van-field
      v-model="kst_type_name"
      readonly
      label="存储方式"
      placeholder="请选择库位存储方式"
      @click="showKstTypePicker = true"
    />

    <!-- 货架编码 -->
    <van-field
      v-model="role_shelf"
      label="货架编码"
      v-if="kst_type_id === 140010 || kst_type_id === 140011"
      autocomplete="off"
      placeholder=""
    />

    <!-- 货架行号 -->
    <van-field
      v-model="role_line"
      label="货架行号"
      v-if="kst_type_id === 140010 || kst_type_id === 140011"
      autocomplete="off"
      placeholder=""
    />

    <!-- 库位编码 -->
    <van-field
      v-model="role_position"
      label="库位编码"
      v-if="kst_type_id !== 140011"
      autocomplete="off"
      placeholder=""
    />

    <!-- 客户选择 -->
    <van-field
      v-model="customer_name"
      readonly
      label="客户选择"
      placeholder="请选择客户"
      @click="showCustomerPicker = true"
    />

    <!-- 备注 -->
    <van-field
      v-model="notes"
      rows="3"
      autosize
      label="备注"
      type="textarea"
      maxlength="150"
      placeholder="备注信息"
      show-word-limit
    />

    <van-divider></van-divider>

    <div class="btn-box">
      <van-button
        style="width: 30%; margin-right: 15px"
        round
        size="small"
        icon="exchange"
        type="success"
        @click="initStorage"
      >
        确认
      </van-button>
      <van-button
        style="width: 30%"
        round
        size="small"
        icon="replay"
        type="default"
        @click="initData"
      >
        重置
      </van-button>
    </div>

    <!-- 存储方式选择器 -->
    <van-popup v-model:show="showKstTypePicker" round position="bottom">
      <van-picker
        :columns="columns"
        @cancel="showKstTypePicker = false"
        @confirm="onConfirm"
      />
    </van-popup>

    <!-- 客户选择器 -->
    <customer-picker
      v-model:show="showCustomerPicker"
      v-model:customer_id="customer_id"
      v-model:customer_name="customer_name"
    ></customer-picker>
  </div>
</template>

<script>
import { initStorageByTagId } from "./api";
import { getCurrentInstance, onMounted, ref } from "vue";
import customerPicker from "@/components/customerPicker";
export default {
  components: {
    customerPicker,
  },
  setup() {
    const internalInstance = getCurrentInstance();
    const utils = internalInstance.appContext.config.globalProperties.$utils;
    const kst_tag_input_dom = ref(null); //Dom对象

    const kst_tag_id = ref(""); //库位RFID
    const kst_type_name = ref(""); //存储方式名称
    const kst_type_id = ref(""); //存储方式ID
    const role_shelf = ref(""); //货架编码
    const role_line = ref(""); //货架行号
    const role_position = ref(""); //库位编码
    const customer_name = ref(""); //客户姓名
    const customer_id = ref(""); //客户UID
    const notes = ref(""); //备注

    const showKstTypePicker = ref(false); //控制存储方式选择是否显示
    const showCustomerPicker = ref(false); //控制客户选择是否显示
    const columns = ref([
      { text: "货架存放", value: 140010 },
      { text: "货架行存", value: 140011 },
      { text: "散堆存放", value: 140012 },
      { text: "垛堆存放", value: 140013 },
      { text: "成组堆码存放", value: 140014 },
    ]);

    //确认存储方式选择
    const onConfirm = (val) => {
      kst_type_name.value = val.text;
      kst_type_id.value = val.value;
      showKstTypePicker.value = false;
    };

    //控制输入框获取焦点单不弹出键盘
    const gainFocus = () => {
      initExpressInput(); //初始化输入框
      //找到input的dom对象
      const inpdom =
        kst_tag_input_dom.value.$el.children[1].children[0].children[0];
      inpdom.setAttribute("readonly", "readonly");
      inpdom.focus();
      setTimeout(() => {
        inpdom.removeAttribute("readonly");
      }, 200);
    };

    //初始化快递单号输入框
    const initExpressInput = () => {
      kst_tag_id.value = "";
    };

    const initData = () => {
      kst_tag_id.value = ""; //库位ID
      kst_type_name.value = ""; //存储方式名称
      kst_type_id.value = ""; //存储方式ID
      role_shelf.value = ""; //货架号
      role_line.value = ""; //货架行号
      role_position.value = ""; //库位号
      customer_id.value = ""; //客户ID
      customer_name.value = ""; //客户姓名
      notes.value = ""; //描述
    };

    //提交入库请求
    const initStorage = () => {
      const params = {
        kst_tag_id: kst_tag_id.value, //库位ID
        type: kst_type_id.value, //存储方式ID
        role_shelf: role_shelf.value, //货架号
        role_line: role_line.value, //货架行号
        role_position: role_position.value, //库位号
        kcu_id: customer_id.value, //客户ID
        status: 1, //状态
        notes: notes.value, //描述
      };
      initStorageByTagId(params)
        .then((res) => {
          initData();
          utils.success(res.err_msg, 800);
        })
        .catch((err) => {
          utils.fail(err.err_msg, 1000);
        });
    };

    onMounted(() => {
      gainFocus();
    });

    return {
      kst_tag_input_dom,
      gainFocus,
      initStorage,
      onConfirm,
      kst_tag_id,
      kst_type_name,
      kst_type_id,
      showKstTypePicker,
      columns,
      role_shelf,
      role_line,
      role_position,
      notes,
      initData,
      showCustomerPicker,
      customer_name,
      customer_id,
    };
  },
};
</script>
