/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-07 01:29:06
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-14 02:14:43
 * @Description: 请输入文件描述信息
 * @FilePath: \src\views\Storage\api.js
 */

import http from "@/network/network.js";

// 初始化库位
export const initStorageByTagId = (params) =>
  http.put("/v1/Storage/InitStorageByTagId", params);

// 修改库位信息
export const modifyStorageByTagId = (params) =>
  http.put("/v1/Storage/ModifyStorageByTagId", params);
